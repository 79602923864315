<template>
  <div class="assessmentReportCom">
    <div class="content">
      <div
        class="car_check_box"
        v-for="(item, index) in checkList"
        :key="index"
      >
        <div
          class="car_check_title"
          @click="toggle(item.areaId)"
          :id="'scrollItem_' + item.areaId"
        >
          <div style="display: flex; align-items: center">
            <span>{{ item.title }}</span>
            <img v-if="item.isHasDefect == 0" class="icon" src="../assets/3.png" alt="" />
            <img v-if="item.isHasDefect == 1" class="icon" src="../assets/4.png" alt="" />
          </div>
          <img
            v-if="flag != item.areaId"
            class="arrow"
            src="../assets/8.png"
            alt=""
          />
          <img
            v-if="flag == item.areaId"
            class="arrow"
            src="../assets/9.png"
            alt=""
          />
        </div>
        <div class="car_check_item_box" v-if="flag == item.areaId">
          <!-- 漆面检测&&磨损检测有车辆骨架图 -->
          <div v-if="item.areaId == 5 || item.areaId == 6" class="car_img_box">
            <img class="car_img" src="../assets/5.png" alt="" />
            <template v-for="item in item.detectItem" :key="item.id">
              <img
                v-if="item.isHasDefect == 0"
                :style="{ top: item.top, left: item.left }"
                src="../assets/3.png"
                alt=""
                class="car_img_check_icon"
              />
              <img
                v-if="item.isHasDefect == 1"
                :style="{ top: item.top, left: item.left }"
                src="../assets/4.png"
                alt=""
                class="car_img_check_icon"
              />
            </template>
          </div>

          <template v-if="item.areaId != 7 && item.areaId != 0">
            <div>
              <div
                v-for="(items, i) in item.detectItem"
                :key="i"
                class="car_check_item"
              >
                <div class="car_img_item">
                  <span>{{ items.name }}</span>
                  <span :class="items.isHasDefect == 1 ? 'active' : ''">{{
                    items.statusName
                  }}</span>
                </div>

                <div v-if="items.defectRemark" class="" style="margin-top:10px">
                  {{items.defectRemark}}
                </div>

                <div v-if="items.defectPic&&items.defectPic.length" class="imgBox" v-viewer>
                  <img v-for="(item,index) in items.defectPic" :key="index" :src="item.url" />
                </div>
              </div>
            </div>
          </template>

          <!-- 车辆描述 -->
          <template v-if="item.areaId == 7">
            <div class="description">
              {{ item.detectItem[0].defectRemark }}
            </div>
          </template>
          
          <!-- 车辆检测图片 -->
          <template v-if="item.areaId == 0">
            <template v-if="item.defectPic&&item.defectPic.length">

              <div class="imgBox" v-viewer>
                <img v-for="(item,index) in item.defectPic" :key="index" :src="item.url" />
              </div>

            </template>
            <template v-else>
              <img
                style="display: block; margin: 15px auto"
                src="../assets/14.png"
                alt=""
              />
              <p style="color: #797979; font-size: 10px; text-align: center">
                暂无检测图片
              </p>
            </template>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["flagId", "checkArr", "areaid"],
  data() {
    return {
      paintList: [
        {
          id: "065",
          name: "前保险杠漆面是否正常",
          left: "3.5%",
          top: "47%",
        },
        {
          id: "066",
          name: "前盖漆面是否正常",
          left: "25%",
          top: "47%",
        },
        {
          id: "067",
          name: "左前翼子外板漆面是否正常",
          left: "20%",
          top: "88%",
        },
        {
          id: "068",
          name: "左前门漆面是否正常",
          left: "43%",
          top: "84%",
        },
        {
          id: "069",
          name: "左后门漆面是否正常",
          left: "56%",
          top: "84%",
        },
        {
          id: "070",
          name: "左后翼子外板漆面是否正常",
          left: "74.5%",
          top: "88%",
        },
        {
          id: "071",
          name: "后盖漆面是否正常",
          left: "69%",
          top: "47%",
        },
        {
          id: "072",
          name: "后保险杠漆面是否正常",
          left: "93%",
          top: "47%",
        },
        {
          id: "073",
          name: "车顶漆面是否正常",
          left: "54.5%",
          top: "47%",
        },
        {
          id: "074",
          name: "右后翼子外板漆面是否正常",
          left: "74.5%",
          top: "6%",
        },
        {
          id: "075",
          name: "右后门漆面是否正常",
          left: "56%",
          top: "9%",
        },
        {
          id: "076",
          name: "右前门漆面是否正常",
          left: "43%",
          top: "9%",
        },
        {
          id: "077",
          name: "右前翼子外板漆面是否正常",
          left: "20%",
          top: "6%",
        },
      ],
      abrasionList: [
        {
          id: "078",
          name: "前保险杠是否有划伤或凹痕",
          left: "3.5%",
          top: "47%",
        },
        {
          id: "079",
          name: "前盖是否有划伤或凹痕",
          left: "25%",
          top: "47%",
        },
        {
          id: "080",
          name: "左前翼子外板是否有划伤或凹痕",
          left: "20%",
          top: "88%",
        },
        {
          id: "081",
          name: "左前门是否有划伤或凹痕",
          left: "43%",
          top: "84%",
        },
        {
          id: "082",
          name: "左后门是否有划伤或凹痕",
          left: "56%",
          top: "84%",
        },
        {
          id: "083",
          name: "左后翼子外板是否有划伤或凹痕",
          left: "74.5%",
          top: "88%",
        },
        {
          id: "084",
          name: "后盖是否有划伤或凹痕",
          left: "69%",
          top: "47%",
        },
        {
          id: "085",
          name: "后保险杠是否有划伤或凹痕",
          left: "93%",
          top: "47%",
        },
        {
          id: "086",
          name: "车顶是否有划伤或凹痕",
          left: "54.5%",
          top: "47%",
        },
        {
          id: "087",
          name: "右后翼子外板是否有划伤或凹痕",
          left: "74.5%",
          top: "6%",
        },
        {
          id: "088",
          name: "右后门是否有划伤或凹痕",
          left: "56%",
          top: "9%",
        },
        {
          id: "089",
          name: "右前门是否有划伤或凹痕",
          left: "43%",
          top: "9%",
        },
        {
          id: "090",
          name: "右前翼子外板是否有划伤或凹痕",
          left: "20%",
          top: "6%",
        },
      ],

      checkList: [],
      flag: this.flagId,
    };
  },
  computed: {},
  mounted() {
    let that = this;
    that.checkList = that.checkArr;

    that.checkList.forEach((e) => {
      e.detectItem.forEach((t) => {
        for (let i in t.detectItemLevel) {
          t.statusName = "正常";
          if (t.detectItemLevel[i].isCheck == 1) {
            t.statusName = t.detectItemLevel[i].name;
            break;
          }
        }

        if (t.areaId == 5) {
          that.paintList.map((e) => {
            if (t.id == e.id) {
              t.left = e.left;
              t.top = e.top;
            }
          });
        }

        if (t.areaId == 6) {
          that.abrasionList.map((e) => {
            if (t.id == e.id) {
              t.left = e.left;
              t.top = e.top;
            }
          });
        }

        if (t.areaId == 7) {
          that.checkList.push({
            areaId: 0,
            title: "车辆检测图片",
            defectPic: t.defectPic,
          });
        }
      });
    });

    this.$nextTick(() => {
      if (this.areaid) {
        document.getElementById("scrollItem_" + this.areaid).scrollIntoView();
      }
    });
  },
  methods: {
    toggle(e) {
      if (this.flag == e) {
        this.flag = null;
        return;
      }
      this.flag = e;
    },
  },
};
</script>
<style lang='scss'>
.assessmentReportCom {
  width: 100vw;
  max-width: 750px;
  margin: 0 auto;
}

.assessmentReportCom {
  background: #f3f5f7;
  overflow: hidden;
  .content {
    width: 100%;
    border-radius: 8px;
    background: #ffffff;
    box-sizing: border-box;
    padding: 0 15px;
    margin: 0 auto;
    padding-bottom: 50px;
    .car_check_box {
      .car_check_title {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 35px;
        cursor: pointer;
        user-select: none;
        -webkit-tap-highlight-color: transparent;
        span {
          min-width: 100px;
          display: inline-block;
          font-family: PingFang-SC-Medium;
          font-size: 14px;
          color: #292d33;
          font-weight: bold;
        }
        .icon {
          width: 16px;
          height: 16px;
          margin-left: 10px;
        }
        .arrow {
          width: 18px;
          height: 18px;
          justify-items: right;
          transform: rotate(0deg);
          transition: all 0.3s;
        }
        &:target {
          background: #ffffff;
        }
      }
      
      .car_check_item_box {
        margin-bottom: 10px;
        padding-bottom: 15px;
        border-bottom: 1px solid rgba($color: #e9e9e9, $alpha: 0.5);
        .car_img_box {
          width: 100%;
          position: relative;
          margin-top: 10px;
          margin-bottom: 20px;
          .car_img {
            width: 100%;
            display: block;
          }
          .car_img_check_icon {
            width: 16px;
            height: 16px;
            position: absolute;
          }
        }
        .car_check_item {
          .car_img_item {
            display: flex;
            justify-content: space-between;
            margin-top: 10px;
            font-size: 14px;
            span {
              display: inline-block;
              font-family: PingFang-SC-Regular;
            }
            span:first-child {
              width: 70%;
              color: #979797;
            }
            span:nth-child(2) {
              width: 30%;
              text-align: right;
              font-size: 14px;
              color: #292d33;
            }
            .active {
              color: #ff0000 !important;
            }
          }
        }
        .description {
          font-size: 14px;
          text-align: left;
          font-family: PingFang-SC-Regular;
          color: #292d33;
        }
      }
      &:last-child {
        .car_check_item_box {
          border: none;
        }
      }
    }
    .imgBox {
      width: 95px;
      height: 95px;
      border: 1px solid #e9e9e9;
      margin-right: 10px;
      margin-top: 10px;
      display: inline-block;
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
  }
}
</style>